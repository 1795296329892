import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ChevronDown } from "lucide-react";
import styled from "@emotion/styled";

const ScrollButtonWrapper = styled.div`
  position: absolute;
  right: 50px;
  bottom: 250px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #5b47c8; /* Daha koyu bir arka plan rengi */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; /* Tıklanabilir olduğunu göstermek için */
  z-index: 100; /* Üstte kalması için */

  /* Shadow için pseudo-element */
  &::after {
    content: "";
    position: absolute;
    inset: -10px; /* Gölgeleri büyütmek için */
    background: linear-gradient(
      to bottom right,
      rgba(91, 71, 200, 0.8),
      /* Daha koyu ve yoğun temel renk */ rgba(255, 213, 0, 0.7)
        /* Daha hafif sarı */
    );
    filter: blur(15px); /* Gölge bulanıklığı */
    border-radius: 50%; /* Çember şeklinde gölge */
    z-index: -1; /* Gölgeyi arkaya yerleştirmek için */
  }

  /* Medya sorgusu */
  @media (max-width: 768px) {
    bottom: 300px; 
  }
`;


const RotatingTextWrapper = styled.div`
  position: absolute;
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
`;

const CircularText = styled.span`
  position: absolute;
  font-size: 10px;
  color: #c8f95b;
  font-family: Arial, sans-serif;
  text-transform: uppercase;
  transform: rotate(${(props) => props.angle}deg) translate(0, -70px);
  transform-origin: center;
`;

const CenterIcon = styled.div`
  position: relative;
  color: black;
`;

const ScrollDownButton = ({ onClick }) => {
  const textRef = useRef();

  useEffect(() => {
    // GSAP ile dönen animasyon
    gsap.to(textRef.current, {
      rotation: 360,
      duration: 10,
      ease: "linear",
      repeat: -1,
    });
  }, []);

  const text = "SCROLL DOWN • SCROLL DOWN •";
  const characters = text.split("");

  return (
    <ScrollButtonWrapper onClick={onClick}>
      <RotatingTextWrapper ref={textRef}>
        {characters.map((char, index) => (
          <CircularText key={index} angle={(360 / characters.length) * index}>
            {char}
          </CircularText>
        ))}
      </RotatingTextWrapper>
      <CenterIcon>
        <ChevronDown size={32} />
      </CenterIcon>
    </ScrollButtonWrapper>
  );
};

export default ScrollDownButton;
