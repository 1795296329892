"use client";

import { useEffect, useState } from "react";
import Spline from "@splinetool/react-spline";

export default function SplineBanner() {
  const DEFAULT_SPLINE_LINK =
    "https://prod.spline.design/ckmpdLUAWIRffmuh/scene.splinecode"; // Varsayılan link
  const STORAGE_KEY = "homepageSpline"; // LocalStorage anahtarı

  const [splineLink, setSplineLink] = useState(() =>
    // İlk yüklemede localStorage'dan linki al
    typeof window !== "undefined" ? localStorage.getItem(STORAGE_KEY) : null
  );

  useEffect(() => {
    if (!splineLink) {
      // Eğer state'te splineLink yoksa varsayılanı kaydet ve state'e ata
      localStorage.setItem(STORAGE_KEY, DEFAULT_SPLINE_LINK);
      setSplineLink(DEFAULT_SPLINE_LINK);
    }
  }, [splineLink]);

  return (
    <div className="w-[250px] h-[250px] md:w-[600px] md:h-[800px] splineBanner pointer-events-none md:pointer-events-auto">
      {splineLink && <Spline scene={splineLink} />}
    </div>
  );
}
