import { Camera } from "lucide-react";
import { Server } from "lucide-react";
import { UserCircle } from "lucide-react";
import { HelpCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

export const Menus = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About Us",
    subMenu: [
      {
        name: "Who We Are",
        path: "/about",
        icon: UserCircle,
      },
      {
        name: "Frequently Asked Questions",
        path: "/frequentlyaskedquestions",
        icon: HelpCircle,
      },
    ],
  },
  {
    name: "Products",
    subMenuHeading: ["Get started", "Programs", "Recent"],
    subMenu: [
      {
        name: "Safety Monitoring",
        path: "/safetymonitoring",
        icon: Camera,
      },
      {
        name: "SiteIQ",
        path: "/siteiq",
        icon: Server,
      },
    ],
    gridCols: 1,
  },
  {
    name: "Contact Us",
    path: "/contact",
  },
];
