"use client";

import React, { useEffect, useState, useRef } from "react";
import { motion, useInView } from "framer-motion";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Roadmap from "../components/RoadMap";
import BlurryCursor from "../components/BlurryCursor";
import Spline from "@splinetool/react-spline";
import OurTeam from "../components/OurTeam";
import "../App.css";
import LoadingScreen from "../components/LoadingScreen";
import BackgroundAnimation from "../components/BackgroundAnimation";

const About = () => {
  const [isActive, setIsActive] = useState(false);

  const WEB_SPLINE_KEY = "webSplineScene";
  const MOBILE_SPLINE_KEY = "mobileSplineScene";

  const DEFAULT_WEB_SPLINE_LINK =
    "https://prod.spline.design/1OIsklrAdJWvUj0A/scene.splinecode";
  const DEFAULT_MOBILE_SPLINE_LINK =
    "https://prod.spline.design/6fprb5FmK32T7Fb5/scene.splinecode";

  const [webSplineLink, setWebSplineLink] = useState("");
  const [mobileSplineLink, setMobileSplineLink] = useState("");

  useEffect(() => {
    // Web için Spline linkini kontrol et
    const storedWebLink = localStorage.getItem(WEB_SPLINE_KEY);
    if (storedWebLink) {
      setWebSplineLink(storedWebLink);
    } else {
      localStorage.setItem(WEB_SPLINE_KEY, DEFAULT_WEB_SPLINE_LINK);
      setWebSplineLink(DEFAULT_WEB_SPLINE_LINK);
    }

    // Mobil için Spline linkini kontrol et
    const storedMobileLink = localStorage.getItem(MOBILE_SPLINE_KEY);
    if (storedMobileLink) {
      setMobileSplineLink(storedMobileLink);
    } else {
      localStorage.setItem(MOBILE_SPLINE_KEY, DEFAULT_MOBILE_SPLINE_LINK);
      setMobileSplineLink(DEFAULT_MOBILE_SPLINE_LINK);
    }
  }, []);

  const timelineRef = useRef(null);
  const isInView = useInView(timelineRef, { once: true, margin: "-20%" });

  return (
    <>
      <BackgroundAnimation />
      <div className="App overflow-x-hidden relative z-10 mt-12 sm:mt-2">
        <Header />
        <LoadingScreen />
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between p-8 h-screen">
          <div className="w-full md:w-1/2">
            <h1 className="text-[#C8F95B] uppercase text-3xl md:text-[64px]">
              <span className="font-light">About</span>{" "}
              <span className="font-extrabold">Us</span>
            </h1>
            <p className="mt-4 text-lg py-4">
              We are a team of industry experts and technology enthusiasts
              dedicated to transforming construction site management through
              innovative AI solutions. With a diverse background in engineering,
              AI, and construction, our team combines decades of experience and
              a passion for technology to create groundbreaking solutions for
              the construction industry.
            </p>
          </div>

          <div className="w-[700px] h-[700px] md:w-1/2 splineBanner md:inline-block hidden">
            {webSplineLink && <Spline scene={webSplineLink} />}
          </div>

          {/* Mobile Spline */}
          <div className="w-[700px] h-[700px] md:w-1/2 splineBanner md:hidden inline-block">
            {mobileSplineLink && <Spline scene={mobileSplineLink} />}
          </div>
        </div>
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-evenly p-8 relative z-10">
          <div className="about-card">
            <h2>
              <span className="font-light"> OUR</span>{" "}
              <span className="font-extrabold">VISION</span>
            </h2>
            <img src="/shared-vision.png" alt="vision build" />
            <p>
              Our vision is to revolutionize the construction industry with
              AI-driven solutions that enhance safety, efficiency, and project
              management.
            </p>
          </div>
          <div className="about-card">
            <h2 className="font-light">
              <span className="font-light">OUR</span>{" "}
              <span className="font-extrabold">MISSION</span>
            </h2>
            <img src="/target.png" alt="" />
            <p>
              We aim to provide cutting-edge technology that addresses the
              industry's most pressing challenges, ensuring safer and more
              efficient construction sites.
            </p>
          </div>
        </div>
        <div className="container mx-auto flex flex-col items-center justify-evenly px-8 my-10 our-story-section relative z-10">
          <h3 className="font-light">
            <span className="font-light">OUR</span>{" "}
            <span className="font-bold">STORY</span>
          </h3>
          <p>
            We are a team of industry experts and technology enthusiasts
            dedicated to transforming construction site management through
            innovative AI solutions. With a diverse background in engineering,
            AI, and construction, our team combines decades of experience and a
            passion for technology to create groundbreaking solutions for the
            construction industry.
          </p>
          <p>
            Recognizing the strategic importance of Amsterdam's tech ecosystem,
            we established a base in the heart of the city. We proudly partnered
            with TNW (The Next Web), a Financial Times company, positioning us
            alongside some of the most prominent names in technology and
            innovation. This partnership has enabled us to thrive in a dynamic
            startup environment, leveraging TNW's extensive network, resources,
            and expertise.
          </p>
        </div>
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-8 my-10 what-do-section">
          <div className="w-full md:w-1/2">
            <h3>
              <span className="font-light">WHAT</span>{" "}
              <span className="font-extrabold">WE DO</span>
            </h3>
            <p>
              Our solutions are designed to address the unique needs of the
              construction industry, focusing on real-time safety monitoring and
              enhanced site management. Our flagship product, the Safety Gear
              and Restricted Area Monitoring system, uses existing security
              cameras to detect safety gear compliance and monitor restricted
              zone breaches. Upcoming enhancements include task automation and
              workflow streamlining features to further improve site management
              capabilities.
            </p>
          </div>
          <div className="w-full md:w-1/2 flex justify-end">
            <img src="/security-camera.png" alt="Vision Build" />
          </div>
        </div>
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-8 my-10 our-roadmap">
          <div className="flex flex-col justify-center text-center">
            <h3>
              <span className="font-light">OUR</span>{" "}
              <span className="font-extrabold">ROADMAP</span>
            </h3>
            <p>
              Discover our product launch timeline and upcoming innovations.
              Each product is meticulously planned to address specific industry
              challenges and improve overall project outcomes.
            </p>
          </div>
        </div>
        <div className="container mx-auto flex flex-col md:flex-row justify-between what-do-section relative">
          <div className="w-full md:w-1/2">
            <Roadmap />
          </div>
          <div className="w-full md:w-1/2">
            <motion.div
              ref={timelineRef}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
              className="timeline"
            ></motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
              transition={{ duration: 1, ease: "easeOut" }}
              className="timeline"
            >
              SafetyAI Soft Launch
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
              transition={{ duration: 1.2, ease: "easeOut" }}
              className="timeline"
            >
              Official launch of SafetyAI and SiteIQ MVP
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
              transition={{ duration: 1.4, ease: "easeOut" }}
              className="timeline"
            >
              Final integrations for SiteIQ
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 20 }}
              transition={{ duration: 1.6, ease: "easeOut" }}
              className="timeline"
            >
              SiteIQ Soft Launch
            </motion.div>
          </div>
        </div>

        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between our-roadmap mb-10 relative z-10">
          <div className="flex flex-col justify-center text-center">
            <h3>
              <span className="font-light">OUR</span>{" "}
              <span className="font-extrabold">TEAM</span>
            </h3>
            <p>
               Meet the talented professionals behind VisionBuild, including our
              founders, engineers, and industry advisors. Our team is composed
              of seasoned professionals in AI, software development, and
              construction management, all working together to deliver
              exceptional solutions.
            </p>
            <OurTeam />
          </div>
        </div>
        <Footer />
        <BlurryCursor isActive={isActive} />
      </div>
    </>
  );
};

export default About;
