import React, { useState } from "react";
import Container from "./Container";

const OurTeam = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Container>
        <div className="flex justify-center items-center flex-col my-10 relative z-10 md:hidden">
          <div className="flex justify-between items-center gap-x-8 gap-y-5 flex-col md:flex-row flex-wrap">
            <div className="our-team-card">
              <div className="our-team-card-img flex flex-col sm:flex-row items-center justify-evenly">
                <img src="/ugur-erkal.jpeg" alt="Uğur Erkal" className="mb-2" />
                <div className="card-title-team flex flex-col">
                  <h4>Uğur Erkal</h4>
                  <h5>Co-Founder, Operational Director</h5>
                </div>
              </div>
              <div className="card-parag">
                <p>
                  With a strong background in strategic leadership and
                  marketing, Uğur Erkal drives Visionbuild's growth and global
                  expansion, ensuring the brand's strength across industries.
                </p>
              </div>
            </div>
            <div className="our-team-card">
              <div className="our-team-card-img flex flex-col sm:flex-row items-center justify-evenly">
                <img
                  src="/izlen-geneci-emel.jpeg"
                  alt="İzlen Geneci Emel"
                  className="mb-2"
                />
                <div className="card-title-team flex flex-col">
                  <h4>İzlen Geneci Emel</h4>
                  <h5>Co-Founder, Technical Director</h5>
                </div>
              </div>
              <div className="card-parag">
                <p>
                  With a strong background in technology and AI development,
                  İzlen oversees the creation of innovative solutions tailored
                  to the construction industry.
                </p>
              </div>
            </div>
            <div className="our-team-card">
              <div className="our-team-card-img flex flex-col sm:flex-row items-center justify-evenly">
                <img
                  src="/ahmet.jpeg"
                  alt="Ahmet Bahadır Lülecioğlu"
                  className="mb-2"
                />
                <div className="card-title-team flex flex-col">
                  <h4>Ahmet Bahadır Lülecioğlu</h4>
                  <h5>Co-Founder, Civil Engineer</h5>
                </div>
              </div>
              <div className="card-parag">
                <p>
                  With over 20 years of experience in the construction sector,
                  Ahmet manages our financial strategy, ensuring long-term
                  sustainability and growth.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center gap-x-8 gap-y-5 flex-col md:flex-row flex-wrap">
            <div className="our-team-card">
              <div className="our-team-card-img flex flex-col sm:flex-row items-center justify-evenly">
                <img
                  src="/yildizdikme.jpeg"
                  alt="Yildiz Dikme"
                  className="mb-2"
                />
                <div className="card-title-team flex flex-col">
                  <h4>Yıldız Dikme</h4>
                  <h5>Frontend Developer</h5>
                </div>
              </div>
              <div className="card-parag">
                <p>
                  Yıldız oversees the development of animations, UI/UX design,
                  and coding for VisionBuild, ensuring seamless integration of
                  user experience and functionality
                </p>
              </div>
            </div>
            <div className="our-team-card">
              <div className="our-team-card-img flex flex-col sm:flex-row items-center justify-evenly">
                <img src="/ekin-ozcoban.jpeg" alt="" className="mb-2" />
                <div className="card-title-team flex flex-col">
                  <h4>Ekin Özçoban</h4>
                  <h5>UX/UI Designer</h5>
                </div>
              </div>
              <div className="card-parag">
                <p>
                  As a passionate UX/UI designer, Ekin brings a balance of
                  creativity and practicality to crafting intuitive,
                  user-centered designs.
                </p>
              </div>
            </div>
            <div className="our-team-card">
              <div className="our-team-card-img flex flex-col sm:flex-row items-center justify-evenly">
                <img src="/carlos.jpeg" alt="carlo lasorte" className="mb-2" />
                <div className="card-title-team flex flex-col">
                  <h4>Carlo Lasorte</h4>
                  <h5>Commercial & Partner Director</h5>
                </div>
              </div>
              <div className="card-parag">
                <p>
                  With expertise in business development and sales, Carlo
                  spearheads our commercial strategy, driving revenue growth and
                  forging strong partnerships across various sectors.
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center gap-x-8 gap-y-5 flex-col md:flex-row flex-wrap">
            <div className="our-team-card">
              <div className="our-team-card-img flex flex-col sm:flex-row items-center justify-evenly">
                <img
                  src="/alihan-tabak.jpeg"
                  alt="Alihan Tabak"
                  className="mb-2"
                />
                <div className="card-title-team flex flex-col">
                  <h4>Alihan Tabak</h4>
                  <h5>DevOps</h5>
                </div>
              </div>
              <div className="card-parag">
                <p>
                  Alihan is a highly experienced professional in the fields of
                  technology, AI, and DevOps, providing consultancy services in
                  these areas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="hidden md:grid grid-cols-4 gap-y-6 my-10">
        {/* Uğur Erkal */}
        <article className="card card--team-member group">
          <figure className="card__figure">
            <div className="avatar avatar--regular bg-emerald card__figure-avatar">
              <img
                src="/ugur-erkal.jpeg"
                className="avatar__image droplet-image"
                alt="Uğur Erkal"
              />
            </div>
            <video
              src="/ugur-gif.mp4"
              className="card__figure-animated"
              autoPlay
              loop
              muted
              playsInline
            />
          </figure>

          <div className="card__body">
            <button className="card__header">
              <div className="card__details">
                <div className="card__name">Uğur Erkal</div>
                <div className="card__function">
                  Co-Founder, Operational Director
                </div>
              </div>
            </button>
            <div className="card__biography" style={{ height: "auto" }}>
              <div className="card__biography-inner">
                With a strong background in strategic leadership and marketing,
                Uğur Erkal drives Visionbuild's growth and global expansion.
              </div>
            </div>
          </div>
        </article>

        {/* İzlen Geneci Emel */}
        <article className="card card--team-member group">
          <figure className="card__figure">
            <div className="avatar avatar--regular bg-emerald card__figure-avatar">
              <img
                src="/izlen-geneci-emel.jpeg"
                className="avatar__image droplet-image"
                alt="İzlen Geneci Emel"
              />
            </div>
            <video
              src="/izlen-gif.mp4"
              className="card__figure-animated"
              autoPlay
              loop
              muted
              playsInline
            />
          </figure>

          <div className="card__body">
            <button className="card__header">
              <div className="card__details">
                <div className="card__name">İzlen Geneci Emel</div>
                <div className="card__function">
                  Co-Founder, Technical Director
                </div>
              </div>
            </button>
            <div className="card__biography" style={{ height: "auto" }}>
              <div className="card__biography-inner">
                With a strong background in technology and AI development, İzlen
                oversees the creation of innovative solutions tailored to the
                construction industry.
              </div>
            </div>
          </div>
        </article>

        {/* Ahmet Bahadır Lülecioğlu */}
        <article className="card card--team-member group">
          <figure className="card__figure">
            <div className="avatar avatar--regular bg-emerald card__figure-avatar">
              <img
                src="/ahmet.jpeg"
                className="avatar__image droplet-image"
                alt="Ahmet Bahadır Lülecioğlu"
              />
            </div>
            <video
              src="/ahmet-gif.mp4"
              className="card__figure-animated"
              autoPlay
              loop
              muted
              playsInline
            />
          </figure>

          <div className="card__body">
            <button className="card__header">
              <div className="card__details">
                <div className="card__name">Ahmet Bahadır Lülecioğlu</div>
                <div className="card__function">Co-Founder, Civil Engineer</div>
              </div>
            </button>
            <div className="card__biography" style={{ height: "auto" }}>
              <div className="card__biography-inner">
                With over 20 years of experience in the construction sector,
                Ahmet manages our financial strategy, ensuring long-term
                sustainability and growth.
              </div>
            </div>
          </div>
        </article>
        {/* Carlo Lasorte */}
        <article className="card card--team-member group">
          <figure className="card__figure">
            <div className="avatar avatar--regular bg-emerald card__figure-avatar">
              <img
                src="/carlos.jpeg"
                className="avatar__image droplet-image"
                alt="Carlo Lasorte"
              />
            </div>
            <video
              src="/carlos-gif.mp4"
              className="card__figure-animated"
              autoPlay
              loop
              muted
              playsInline
            />
          </figure>

          <div className="card__body">
            <button className="card__header">
              <div className="card__details">
                <div className="card__name">Carlo Lasorte</div>
                <div className="card__function">
                  Commercial & Partner Director
                </div>
              </div>
            </button>
            <div className="card__biography" style={{ height: "auto" }}>
              <div className="card__biography-inner">
                With expertise in business development and sales, Carlo
                spearheads our commercial strategy, driving revenue growth and
                forging strong partnerships across various sectors.
              </div>
            </div>
          </div>
        </article>
        {/* Alihan Tabak */}
        <article className="card card--team-member group">
          <figure className="card__figure">
            <div className="avatar avatar--regular bg-emerald card__figure-avatar">
              <img
                src="/alihan-tabak.jpeg"
                className="avatar__image droplet-image"
                alt="Alihan Tabak"
              />
            </div>
            <video
              src="/alihan-gif-two.mp4"
              className="card__figure-animated"
              autoPlay
              loop
              muted
              playsInline
            />
          </figure>

          <div className="card__body">
            <button className="card__header">
              <div className="card__details">
                <div className="card__name">Alihan Tabak</div>
                <div className="card__function">DevOps Engineer</div>
              </div>
            </button>
            <div className="card__biography" style={{ height: "auto" }}>
              <div className="card__biography-inner">
                Alihan is a highly experienced professional in the fields of
                technology, AI, and DevOps, providing consultancy services in
                these areas.
              </div>
            </div>
          </div>
        </article>

        {/* Ekin Özçoban */}
        <article className="card card--team-member group">
          <figure className="card__figure">
            <div className="avatar avatar--regular bg-emerald card__figure-avatar">
              <img
                src="/ekin-ozcoban.jpeg"
                className="avatar__image droplet-image"
                alt="Ekin Özçoban"
              />
            </div>
            <video
              src="/ekin-gif.mp4"
              className="card__figure-animated"
              autoPlay
              loop
              muted
              playsInline
            />
          </figure>

          <div className="card__body">
            <button className="card__header">
              <div className="card__details">
                <div className="card__name">Ekin Özçoban</div>
                <div className="card__function">UX/UI Designer</div>
              </div>
            </button>
            <div className="card__biography" style={{ height: "auto" }}>
              <div className="card__biography-inner">
                As a passionate UX/UI designer, Ekin brings a balance of
                creativity and practicality to crafting intuitive, user-centered
                designs.
              </div>
            </div>
          </div>
        </article>
        {/* Yıldız Dikme */}
        <article className="card card--team-member group">
          <figure className="card__figure">
            <div className="avatar avatar--regular bg-emerald card__figure-avatar">
              <img
                src="/yildizdikme.jpeg"
                className="avatar__image droplet-image"
                alt="Yıldız Dikme"
              />
            </div>
            <video
              src="/yildiz-dikme-gif.mp4"
              className="card__figure-animated"
              autoPlay
              loop
              muted
              playsInline
            />
          </figure>

          <div className="card__body">
            <button className="card__header">
              <div className="card__details">
                <div className="card__name">Yıldız Dikme</div>
                <div className="card__function">Frontend Developer</div>
              </div>
            </button>
            <div className="card__biography" style={{ height: "auto" }}>
              <div className="card__biography-inner">
                Yıldız oversees the development of animations, UI/UX design, and
                coding for VisionBuild, ensuring seamless integration of user
                experience and functionality.
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default OurTeam;
